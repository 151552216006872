<template>
    <div>
        <template v-if="!loadLogin">
            <div class="login">
                <div class="encabezado">
                    <img :src="LogoSB" style="width: 300px; margin-left: -7%;" />
                </div>
                <v-form class="form-1" ref="form" v-model="valid" max-witdh="300px">
                <br />
                <p class="bienvenido">¡BIENVENIDO A SOUTHBRIDGE!</p>
                <p></p>
                <p class="ingresa">INGRESA A TU CUENTA</p>
                <br />
                <!-- <label class="labelPlaceholder">Rut</label> -->
                <v-text-field class="v-text-field-style2" v-model="useremail" :rules="emailRules" label="Correo"  required solo filled></v-text-field>
            
                <!-- <label class="labelPlaceholder2">Contraseña</label> -->
                <v-text-field class="v-text-field-style2" v-model="password" type="password" :rules="passwordRules" label="Contraseña" required solo filled></v-text-field>
                <!-- <label class="olvidar">¿Olvidaste tu contraseña?</label> -->
                <br>
                <!-- <button class="BotonIniciar">Ingresar</button>-->
                <div class="button-container">
                    <v-btn class="buttons" @click="validate()">Continuar</v-btn>
                </div>
                <br>
                </v-form>
            </div>
        </template>
        <template v-else>
        <div class="login">
            <div class="encabezado">
            <img :src="LogoSB" style="width: 300px; margin-left: -7%;" />
            </div>
            <form @submit.prevent="handleSubmit">
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
            </form>
        </div>
        </template>
    </div>
 
</template>
<script>
// import dateService from "@/services/date.service.js";
// import planService from "@/services/plans.service.js";
// import RutUtils from "@/utils/rut.utils.js";
// import yearService from "@/services/year.service.js";
import swal from 'sweetalert';
import LogoSB from "@/assets/images/LogoSouthBridge.png";
import ShieldService from '../services/shield.service';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })


export default {
  name: "Login",
  data: () => ({
    LogoSB,
    valid: false,
    clienteNuevo: null,
    useremail:null,
    userName:null,
    password:null,
    newRut: "",
    version: null,
    key: '7cd49960-8289-4e75-adb9-d1503fec31e0',
    dialog: false,
    loginResponse: '',
    loginStatus: '',
    screen: window.screen.width,
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"        
    ],
    passwordRules: [
        (v) => !!v.trim() || 'Debes ingresar tu contraseña',
    ],

  }),
  mounted() {
    // console.log('INIT')   
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      document.body.classList.add('firefox');
    }
  },
  methods: {
    async getUserData(){
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('getUserId')
        try {
          const response = await ShieldService.getUserId(this.loginResponse.data.rut,token);
          if(response.surname){
            this.userName = this.userName + " " + response.surname 
            localStorage.setItem('finalUserName',this.userName)
          }
      } catch (error) {
          console.error('Error obteniendo userId:', error);
          throw error;
      }
    },
    async login() {
      try {
        // await this.$recaptchaLoaded()
        // const tokenConsulta = await this.$recaptcha('consultaSoap');
        const response = await ShieldService.login(this.useremail, this.password);
        // console.log('response: ', response);
        
        return response; 

      } catch (error) {
        console.error('Error for login:', error);
        throw error; 
      }
    },
    encrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); 
    },
    decrypt(text, key) {
      text = atob(text); 
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.loginStatus = 'Success'
        localStorage.setItem("LoginStatus",this.loginStatus)
        this.loginResponse = await this.login()
        // console.log('this.loginResponse: ',this.loginResponse)
        if (this.loginResponse && this.loginResponse.status == 'ok'){
          localStorage.setItem('finalUserRut',this.loginResponse.data.rut)
          localStorage.setItem('finalUserEmail',this.loginResponse.data.email)
          localStorage.setItem('finalUserName',this.loginResponse.data.name)
          this.userName = this.loginResponse.data.name
          await this.getUserData()
          const newkey = this.encrypt(this.loginResponse.data.role, this.key )
          localStorage.setItem('role', newkey);
          this.$router.push({path:"/home"});
        } else {
          swal({
            title: "Error!",
            text: "Ocurrió un error al Iniciar Sesión. Por favor, vuelve a intentarlo.",
            icon: "error",
            button: "Ok",
          });
        }
      }else{
        swal({
              title: "Atención!",
              text: "Debes ingresar tu Correo y Contraseña",
              icon: "info",
              button: "De acuerdo",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* src/App.css */

.login {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-image: url('../assets/images/imagenLoginFinal.png');
  -moz-background-image: url(../assets/images/imagenLoginFinal.png); 
  background-color: #2D387C;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}


h1 {
  margin-bottom: 20px;
}

.encabezado {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 597px;
  height: 100px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.bienvenido {
  color: var(--AD-Primario-1, #2D387C);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.343px;
}

.ingresa {
  color: var(--AD-Primario-2, #4F74E3);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.343px;
  line-height: 30px;
  margin-top: -2%;
  margin-bottom: -2%;
}

.olvidar {
  color: var(--AD-Primario-1, #2D387C);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.343px;
  margin-top: 2%;
  margin-bottom: -1%;
}

.validation {
  color: var(--AD-Primario-1, red);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.343px;
}

/* .datos {
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 1px solid var(--AD-Primario-2, #4F74E3) !important;
  width: 336px;
  height: 37px;
  margin-top: -15px;
  color: #5C6670;
} */

.datos {
  flex-shrink: 0;
  border-radius: 5px !important;
  border: 1px solid var(--AD-Primario-2, #4F74E3) !important;
  width: 336px;
  height: 37px;
  color: #5C6670;
  background-color: #f8f8f8 !important;
  text-indent: 10px; 
}

.labelPlaceholder {
  color: var(--AD-Primario-2, #4F74E3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.667px;
  margin-left: -50%;
  margin-bottom: -2.2%;
  background-color: #f8f8f8 !important;
  z-index: 1;
  width: 7%;
  text-align: center;
}

.labelPlaceholder2 {
  color: var(--AD-Primario-2, #4F74E3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.667px;
  margin-left: -38%;
  margin-bottom: -2.1%;
  background-color: #f8f8f8 !important;
  text-align: center;

  z-index: 1;
  width: 19%;
}

.BotonIniciar {
  display: flex;
  width: 221px;
  height: 34px;
  padding: 7px 21px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 33px;
  background: var(--AD-Primario-2, #4F74E3);
  text-transform: uppercase;
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 597px;
  height: 472px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 0px 0px 8px 8px;
  ;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

button {
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

/* button:hover {
  background-color: #0056b3;
} */

.alert {
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.buttons{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 12px;
    width: 100% !important;
    margin: auto;
}


.modal-more {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 500%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-action {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 500%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* .modal-content {
  width: 671px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 11px;
  background: #FFF;
  text-align: center;
  margin-left: -15%;
} */

.flex-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 45px;
}

.modal-content-inicio-more {
  width: 60%;
  flex-shrink: 0;
  border-radius: 11px;
  background: #FFF;
  text-align: center;
  margin-left: 22%;
  margin-top: 10%;
}

.modal-inicio-more {
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 500%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: -5%;
}

.modal-content-inicio-login {
  width: 50%;
  flex-shrink: 0;
  border-radius: 11px;
  background: #FFF;
  text-align: center;
  margin-left: 22%;
  margin-top: 15%;
}

.modal-inicio-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 500%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: -5%;
}
// .v-text-field-style{
//   box-sizing: border-box;
//   width: 336px;
//   background: #ffffff;
//   border: 1.5px solid rgba(142, 142, 142, 0.2) !important;
//   box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
//   border-radius: 6px;
// }
.v-text-field-style2{
    width: 60%;
    border-radius: 6px;
}
.v-text-field-style2:first-of-type {
  margin-top: 20px; 
}

//FIREFOX STYLE
.firefox .v-text-field-style2:first-of-type {
    margin-top: 40px;
    margin-bottom: -10px; 
}
.firefox .buttons {
  margin-bottom: 10%;
}
.firefox .form-1 {
  height: 420px;
}

@media only screen and (max-width: 768px) {}
</style>