<template>
  <v-container>
    <NavBar :step="2" @retroceder="handlerRetrocederComponente" />

    <Titulo></Titulo>

    <br /><br />

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Plan </v-stepper-step>

        <v-divider></v-divider>

        <!-- <v-stepper-step :complete="e1 > 3" step="3"> Datos </v-stepper-step>

        <v-divider></v-divider> -->

        <v-stepper-step step="3"> Resumen </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Éxito </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="3">
          <div class="step-data">
            <div v-if="this.continue == false">
              <v-form ref="form" v-model="valid" max-witdh="300px">

              <br /><br />

              <h3 class="h3-text">Antes de cotizar te mostraremos un resumen</h3>

              <br /><br />

              <v-card>
                <v-card-text style="color:#000000;opacity: 75%;">
                  <div class="card-text-content">
                    Origen: Chile
                    <br /><br />
                    Plan: {{ polizaData.namePlan }}
                    <br /><br />
                    Cantidad de días: {{ dataQuote.daysNumber }}
                    <br /><br />
                    Email contacto empresa: {{ companyData.email }}
                    <br /><br />
                    Comisión: {{ dataQuote.commissionPercent }}%
                    <br /><br />
                    Valor día: $USD {{ polizaData.valorDias }}
                    <br /><br />
                  </div>
                  <br />
                  <div class="price-box">
                    <br />
                    <hr />
                    <div class="centered-box">
                      <div class="centered-box-content">
                        <div class="price">$USD {{ (dataQuote.priceCLP) }}</div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <br /><br />
                </v-card-text>
              </v-card>

              <br>
              <!-- <div class="button-container" v-if="loadDownload == false">
                <v-btn class="button" style="margin-top: 2%; width: 50%;" @click="descargaPoliza()"
                  :disabled="loadDownload">
                  Descargar cotización
                </v-btn>
              </div> -->
              <!-- <div v-if="loadDownload == true">
                Espera un momento <br>
                <v-progress-circular :size="50" color="#2d387c" indeterminate></v-progress-circular>
              </div> -->
              <br /><br /> <br />

              <h3 class="h3-text">Correos</h3>
              <h4 class="h4-text">Completa los correos de corredor y contratante para pasar a la siguiente etapa</h4>
              <br>
              <v-form ref="form1" v-model="valid" max-witdh="300px">
                <!-- <v-row v-if="loadDownload == false"> -->
                <v-row >
                  <v-text-field class="text-field" v-model="dataQuote.insuranceAgentMail"
                    :rules="emailRules('insuranceAgentMail')" label="Corredor" required solo filled></v-text-field>
                  <div class="button-container2">
                    <v-btn class="button2" @click="envioCorreo(1)">Enviar cotización</v-btn>
                  </div>
                </v-row>
              </v-form>

              <br><br>
              <v-form ref="form2" v-model="valid" max-witdh="300px">
                <!-- <v-row v-if="loadDownload == false"> -->
                <v-row >
                  <v-text-field class="text-field" v-model="dataQuote.contractingPartyMail"
                    :rules="emailRules('contractingPartyMail')" label="Contratante" required solo filled></v-text-field>
                  <div class="button-container2">
                    <v-btn class="button2" @click="envioCorreo(3)">Enviar cotización</v-btn>
                  </div>
                </v-row>
              </v-form>


              <br /><br />

              <div class="button-container" v-if="loadDownload==false && sendDownload==false">
                <!-- <v-btn class="button" @click="envioCorreo(1)">Enviar cotización</v-btn> -->
              </div>

              <div v-if="sendDownload == true">
                Recibirá un correo en los próximos segundos <br>
                <v-progress-circular :size="50" color="#2d387c" indeterminate></v-progress-circular>
              </div>

              <br>
              <div class="button-container">
                <!-- <div class="button-container" v-if="loadDownload == false"> -->
                <v-btn class="button" @click="handleClick(2)">Crear Cotización</v-btn>
              </div>
              <br>
              </v-form>
            </div>

            <div v-if="this.continue == true" class="spinner-container">
              <i class="mdi mdi-loading mdi-spin"></i>
            </div>

          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import swal from 'sweetalert';
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";
import ShieldService from '../services/shield.service';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })




export default {
  name: "Step5",
  components: { NavBar, Titulo },
  data: () => ({
    version: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    e1: 3,
    idTravelPdf: null,
    loadDownload: false,
    sendDownload: false,
    continue: false,
    clienteNuevo: null,
    empresaNuevo: null,
    companyRutSelected: null,
    companyIdSelected: '',
    clientRutSelected: null,
    dataPoliza: null,
    sendEmail: null,
    sendEmail2: null,
    vigencia: null,
    finalUserId: null,
    finalUseRut: null,
    clientId: null,
    dataQuote: {
      daysNumber: null,
      commissionPercent: null,
      insuranceAgentMail: '',
      contractingPartyMail: '',
      priceCLP: null,
      priceUSD: null,
      price: null,
      planId: null,
      planType: null,
      counter: null,
    },
    userData: {
      name: null,
      rut: null,
      email: null,
      dates: null,
      address: null,
      comune: null,
      phoneNumber: null
    },
    companyData: {
      name: null,
      rut: null,
      email: null,
      address: null,
      comune: null,
      phoneNumber: null
    },
    polizaData: {
      fecha: null, //no esta en bd
      nombreCorredor: null, //no esta en bd
      nombreEmpresa: null, //no esta en bd
      rutEmpresa: null, //rut empresa travelcorporatives
      comisionSeleccionada: null, //no esta en bd
      cantidadDias: null, // daysNumber travelcorporatives
      valorDias: null, //no esta en bd --- URGENTE
      valorTotal: null, //no esta en bd --- URGENTE
      namePlan: null, //no esta en bd
      counter: null, //no esta en bd
    },
    dialog: false,
    screen: window.screen.width,
    valid: false,
    emailRules: (fieldName) => [
      (v) => (!!v || !fieldName) || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ]
  }),
 async mounted() {
    this.polizaData.namePlan = localStorage.getItem("namePlan");
    if (this.polizaData.namePlan == 'Plan Platinum Plus INT') {
      this.polizaData.namePlan = 'AC 1M INT (Plan platinum plus internacional)'
    } else if (this.polizaData.namePlan == 'Plan Gold Plus INT') {
      this.polizaData.namePlan = 'AC 500 INT (Plan gold Plus internacional)'
    } else if (this.polizaData.namePlan == 'Plan Silver Plus INT') {
      this.polizaData.namePlan = 'AC 250 INT (Plan silver plus internacional)'
    } else if (this.polizaData.namePlan == 'Plan Platinum Plus INT/NAC') {
      this.polizaData.namePlan = 'AC 1M INT (Plan Platinum Plus INT/NAC)'
    } else if (this.polizaData.namePlan == 'Plan Gold Plus INT/NAC') {
      this.polizaData.namePlan = 'AC 500 INT (Plan Gold Plus INT/NAC)'
    } else if (this.polizaData.namePlan == 'Plan Silver Plus INT/NAC') {
      this.polizaData.namePlan = 'AC 250 INT (Plan Silver Plus INT/NAC)'
    }

    localStorage.setItem("insuranceAgentMail", '');
    localStorage.setItem("contractingPartyMail", '');

    this.userData.name = localStorage.getItem("userName");
    this.userData.rut = localStorage.getItem("userRut");
    this.userData.email = localStorage.getItem("userEmail");
    this.userData.dates = localStorage.getItem("userDates");
    // this.userData.address = localStorage.getItem("userAddress");
    // this.userData.phoneNumber = localStorage.getItem("userPhoneNumber");

    this.dataQuote.commissionPercent = localStorage.getItem("commissionPercent");
    this.dataQuote.daysNumber = parseInt(localStorage.getItem("daysNumber"));
    this.dataQuote.insuranceAgentMail = localStorage.getItem("insuranceAgentMail");
    this.dataQuote.contractingPartyMail = localStorage.getItem("contractingPartyMail");
    this.dataQuote.planType = localStorage.getItem("planType");
    this.dataQuote.planId = localStorage.getItem("planId");

    this.dataQuote.priceCLP = this.formatNumber(parseFloat(localStorage.getItem("priceCLP")));

    this.dataQuote.priceUSD = parseFloat(localStorage.getItem("priceUSD"));
    this.dataQuote.price = localStorage.getItem("price");
    this.dataQuote.counter = parseInt(localStorage.getItem('counter'));

    this.companyData.name = localStorage.getItem("companyName");
    this.companyData.rut = localStorage.getItem("companyRut");
    this.companyData.email = localStorage.getItem("companyEmail");
    this.companyData.address = localStorage.getItem("companyAddress");
    this.companyData.comune = localStorage.getItem("companyComune");
    this.companyData.phoneNumber = localStorage.getItem("companyPhoneNumber");

    this.clienteNuevo = localStorage.getItem("clienteNuevo")
    this.empresaNuevo = localStorage.getItem("empresaNuevo")
 

    this.companyRutSelected = localStorage.getItem("companyRutSelected")
    if (this.companyRutSelected != '' && this.companyRutSelected != 'null' && this.companyRutSelected != null){
      this.companyRutSelected = await ShieldService.getCompanyId(this.companyRutSelected);
      this.companyIdSelected = this.companyRutSelected._id
    } 

    this.polizaData.cantidadDias = parseInt(localStorage.getItem('daysNumber'))
    this.polizaData.comisionSeleccionada = localStorage.getItem('commissionPercent')
    this.polizaData.nombreEmpresa = localStorage.getItem('companyName')
    this.polizaData.rutEmpresa = localStorage.getItem('companyRut')
    this.polizaData.valorTotal = localStorage.getItem('priceCLP')
    this.polizaData.nombreCorredor = localStorage.getItem('corredorName')
    this.polizaData.valorDias = (this.polizaData.valorTotal / this.polizaData.cantidadDias).toFixed(2)
    this.polizaData.counter = localStorage.getItem('counter')

    this.loadDownload = true;
    var newDate = new Date();
    newDate = newDate.toISOString().slice(0, 10).split("-")
    this.vigencia = newDate[2] + "/" + newDate[1] + "/" + (parseInt(newDate[0]) + 1)
    newDate = newDate[2] + "/" + newDate[1] + "/" + newDate[0]
    this.polizaData.fecha = newDate

    var userDate = new Date(this.userData.dates)
    userDate = userDate.toISOString().slice(0, 10).split("-")
    userDate = userDate[2] + "/" + userDate[1] + "/" + userDate[0]
    this.userData.dates = userDate

    this.finalUseRut = localStorage.getItem('finalUserRut')

    await this.getUserId();
    // console.log('this.clienteNuevo: ',this.clienteNuevo)
    // console.log('this.userData.name: ',this.userData.name)
    // console.log('this.userData.rut: ',this.userData.rut)
    // console.log('this.userData.dates: ',this.userData.dates)
    // console.log('this.userData.email: ',this.userData.email)
    await this.createInsurance();

  },
  methods: {
    formatNumber(num) {
      if (num > 1000) {
          return num.toLocaleString('de-DE');
      }
      return num.toString();
    },
    handlerRetrocederComponente(step){
      // console.log('step: ',step)
      this.$emit('cambiar-componente','back'+step);
    },
    async validate() {

      localStorage.setItem("insuranceAgentMail", this.dataQuote.insuranceAgentMail);
      localStorage.setItem("contractingPartyMail", this.dataQuote.contractingPartyMail);
      
      // this.$router.push({ path: "/resumen" });
    },

    formatPrice(number) {
      return this.formatter.format(number);
    },
    async getUserId(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getUserId')
      this.userId = await ShieldService.getUserId(this.finalUseRut,token);
      this.userId = this.userId._id
    },
    async updateQuoteEmails(){
      await ShieldService.updateQuoteEmails(this.idTravelPdf,this.dataQuote.insuranceAgentMail,this.dataQuote.contractingPartyMail);
    },

    async handleClick(index) {
      this.continue = true
      if (this.dataQuote.insuranceAgentMail != null && this.dataQuote.insuranceAgentMail != 'null' && this.dataQuote.insuranceAgentMail != '' && this.dataQuote.contractingPartyMail != null && this.dataQuote.contractingPartyMail != 'null' && this.dataQuote.contractingPartyMail != '' ){
        this.validate();
        await this.envioCorreo(index);
        await this.updateQuoteEmails();
        this.continue = false
        this.$emit('cambiar-componente','step4');
      }else{
        swal({
          title: "¡Ups!",
          text: "Por favor, ingresa los correos del Corredor y el Contratante para continuar.",
          icon: "info",
          button: "Ok",
        });
        this.continue = false
      }
    },
    async envioCorreo(index) {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('sendPdf')
      localStorage.setItem("insuranceAgentMail", this.dataQuote.insuranceAgentMail);
      localStorage.setItem("contractingPartyMail", this.dataQuote.contractingPartyMail);
      if (index == 1) { 
        if (this.dataQuote.insuranceAgentMail != '' && this.$refs.form1.validate()) {
          this.sendDownload = true
          this.sendEmail = await ShieldService.sendPdf(this.dataQuote.insuranceAgentMail, this.companyData.name, this.dataPoliza,token)

          localStorage.removeItem("insuranceAgentMail");
          localStorage.removeItem("contractingPartyMail");

          this.sendDownload = false

        } else {
          swal({
            title: "¡Ups!",
            text: "Por favor, ingresa un correo para enviar la cotización",
            icon: "info",
            button: "Ok",
          });
        }
      } else if (index == 3) {
        if (this.dataQuote.contractingPartyMail != '' && this.$refs.form2.validate()) {

          this.sendDownload = true
          this.sendEmail = await ShieldService.sendPdf(this.dataQuote.contractingPartyMail, this.companyData.name, this.dataPoliza,token)

          localStorage.removeItem("insuranceAgentMail");
          localStorage.removeItem("contractingPartyMail");
          this.sendDownload = false
        } else {
          swal({
            title: "¡Ups!",
            text: "Por favor, ingresa un correo para enviar la cotización",
            icon: "info",
            button: "Ok",
          });
        }
      } else if (index == 2) {
        if (this.clienteNuevo == 'si'){
          await this.$recaptchaLoaded()
          const tokenEmails3 = await this.$recaptcha('sendEmails3')
          // console.log('user email: ',this.companyData.email)
          // Ahora se envian al emitir una cotización
          // if (this.dataQuote.insuranceAgentMail != '') {
          //   this.sendEmail = await ShieldService.sendEmail2(this.dataQuote.insuranceAgentMail, this.companyData.name, this.dataPoliza)
          // } 
          

          // else {
          //   this.sendEmail = await ShieldService.sendEmail2_2(this.companyData.name, this.dataPoliza)
          // }
          this.sendEmail2 = await ShieldService.sendEmail3(this.companyData.email, this.companyData.name, this.polizaData.namePlan, this.dataQuote.daysNumber,this.clienteNuevo, this.userData.name, this.userData.rut, this.userData.dates, this.userData.email, this.vigencia, this.polizaData.fecha,tokenEmails3)
        }
      }

},
    // async generateCounter(){
    //   await this.$recaptchaLoaded()
    //   const token = await this.$recaptcha('addCounter')
    //   console.log('token: ',token)

    //   // this.polizaData.counter = await ShieldService.addCounter(token)
    // },
    async createInsurance() {
      if (this.empresaNuevo == 'no'){
        this.companyData.name = localStorage.getItem('companyName');
        this.companyData.rut = localStorage.getItem('companyRutSelected');
        this.companyData.email = localStorage.getItem('companyEmail');
      }
      var insurance = await ShieldService.createInsurance(this.dataQuote, this.userData, this.companyData, this.polizaData, this.userId, this.clienteNuevo, this.empresaNuevo, this.companyIdSelected);
      // console.log('insurance: ',insurance)
      this.idTravel = localStorage.setItem("idTravelCorporate", insurance.idRes);
      // console.log('insurance: ',insurance)
      this.clientId = insurance.person_id
      // console.log('this.clientID: ',this.clientId)

      this.obtenerPdf();
    },
    async obtenerPdf() {
      //obtener id travel corporate para enviar al create Pdf
      this.idTravelPdf = localStorage.getItem("idTravelCorporate");
      await this.$recaptchaLoaded()
      const tokenConsulta = await this.$recaptcha('createPdf');
      this.dataPoliza = await ShieldService.createPdf(this.idTravelPdf, this.dataQuote.counter, tokenConsulta)
      this.loadDownload = false;
    },
    async descargaPoliza() {

      this.loadDownload = true;


      const byteCharacters = atob(this.dataPoliza);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cotizacion-travel.pdf');
      document.body.appendChild(link);

      // Simular el clic en el enlace para la descarga automática
      link.click();

      // Crear un enlace de descarga
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = "poliza.pdf";

      // Simular un clic en el enlace para iniciar la descarga
      // link.click();
      this.loadDownload = false
      // const response = await ShieldService.createPdf(this.polizaData, { responseType: 'arraybuffer' });
      // const blob = new Blob([response], { type: 'application/pdf' });

      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = "poliza.pdf";

      // // Simular un clic en el enlace para iniciar la descarga
      // link.click();
    }

  },
};
</script>

<style lang="scss">
.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(1)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(3)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(5)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(7)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8e8e8e;
}

.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {
      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .button-container2 {}

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}

hr {
  border: 1px solid #2D387C;
  opacity: 100%;
}


.centered-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 105%);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #2D387C;
  background-color: white;
  width: 70%;
}

.centered-box-content {
  margin-left: 22%;
  display: flex;
  text-align: center;

}

.price {
  margin-left: -16%;
  font-size: 200%;
  margin-right: 2%;
  font-weight: 600;
  color: #2D387C;
}

.alternativePrice {
  font-size: 140%;
  margin-left: 2%;
  color: #2D387C;

}


.h3-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2d387c;
}

.h4-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.text-field {
  box-sizing: border-box;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 0 6px 6px 0;
}

.button {
  background-color: #2d387c !important;
  color: white !important;
  border-radius: 12px;
  width: 100%;
  height: 120%;
}

.button2 {
  background-color: #2d387c !important;
  color: white !important;
  border-radius: 12px;
  width: 80%;
  height: 120%;
}

.card-text-content {
  text-align: left;
}

.v-progress-circular {
  margin: 1rem;
}
.spinner-container {
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100%;
  font-size: 300px;
}
.spinner {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.spinner i {
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } 
}
</style>